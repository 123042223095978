<script>
export default {
  data() {
    return {
      contentText: 'Aucune commande',
    }
  },
}
</script>

<template>
  <div class="page3__right d-flex justify-content-center align-items-center">
    <h1 class="text-center page3__right__text">
      {{ contentText }}
    </h1>
  </div>
</template>

<style lang="scss" scoped>

</style>
