<script>
import Page3 from '@/components/pages/page3.vue'

export default { components: { Page3 } }
</script>

<template>
  <div>
    <Page3 />
  </div>
</template>

<style lang="scss" scoped>

</style>
