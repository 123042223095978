<script>
export default { props: ['color', 'externalClass'] }
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" :class="externalClass">
    <g id="Polygon_7" data-name="Polygon 7" transform="translate(21) rotate(90)" fill="none">
      <path d="M12.5,0,25,21H0Z" stroke="none" />
      <path
        d="M 12.5 5.865288734436035 L 5.276956558227539 18 L 19.72304153442383 18 L 12.5 5.865288734436035 M 12.5 0 L 25 21 L 0 21 L 12.5 0 Z"
        stroke="none" :fill="color || '#ed2894'"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
