<script>
import PlayIconRight from '@/assets/svgs/PolygonRight.vue'
export default {
  components: { PlayIconRight },
  props: [
    'btnText',
    'width',
    'height',
    'playIconColor',
    'externalClass',
    'buttonTextClass',
    'subcontainerClass',
    'MainexternalClass',
  ],
}
</script>

<template>
  <button
    :style="{ width, height }" class="btn btn-primary btn__primary d-flex justify-content-between align-items-center"
    :class="[externalClass, MainexternalClass]"
    @click="$emit('onClick')"
  >
    <div class="d-flex align-items-center ml-3" :class="subcontainerClass">
      <slot name="lefticon" />

      <p class="btn__primary__text " :class="buttonTextClass">
        {{ btnText }}
      </p>
    </div>
    <div class="mr-3 page2__left__btn__playRightIcon">
      <PlayIconRight :color="playIconColor" />
    </div>
  </button>
</template>

<style lang="scss" scoped>

</style>
