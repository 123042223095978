<script>
import User from '@/assets/svgs/User.vue'
import Bottle from '@/assets/svgs/bottle.vue'
import MiniBar from '@/assets/svgs/MiniBar.vue'
import PlayIcon from '@/assets/svgs/polygonLeft.vue'
import IconBtn from '@/components/ui/IconButton.vue'

export default {
  components: {
    User,
    IconBtn,
    Bottle,
    MiniBar,
    PlayIcon,
  },
  data() {
    return {
      signoutText: 'SE DÉCONNECTER',
      user: {
        greet: 'Hello Mr Azawila',
        id: 'Votre Identifiant Client : 46790',
      },
      iconbtns: [
        {
          width: '485.42px',
          height: '51px',
          lefticon: 'Bottle',
          btnText: 'Mes commandes',
          path: '/orders',
          playIconColor: '#FFFFFF',
          class: 'top',
          textclass: 'page2__left__btn__text',
          iconClass: 'page2__left__btn__bottle',
          subcontainerClass: 'page2__left__btn__subContainer',
        },
        {
          width: '485.42px',
          height: '51px',
          lefticon: 'MiniBar',
          btnText: 'Informations du compte',
          path: '/editcustomer',

          playIconColor: '#ED2894',
          class: 'bottom',
          textclass: 'page2__left__btn__text second',
          iconClass: 'page2__left__btn__bar',
          subcontainerClass: 'page2__left__btn__subContainer',
        },
      ],
    }
  },
  computed: {
    customer() {
      return this.$store.state.customer
    },
  },
  methods: {
    signout() {
      this.$cookie.delete('saisstoken')
      this.$store.commit('resetStates')
      this.$store.commit('clearform1')
      this.$store.commit('resetSteps')
      this.$store.commit('clearUserData')
      this.$store.commit('clearOrderData')
      this.$router.push('/')
    },
  },
}
</script>

<template>
  <div class="page2__left">
    <div class="d-flex align-items-center page2__left__user">
      <div class="page2__left__user__iconContainer">
        <User external-class="page2__left__user__icon" />
      </div>
      <div class="page2__left__user__textContainer">
        <h1 class="page2__left__user__name">
          Bonjour {{ customer.civility }} {{ customer.lastName }}
        </h1>
        <p class="page2__left__user__id">
          Votre Identifiant Client : {{ customer.birthDate.replace(/-/g, '') }}{{ customer.id }}
        </p>
      </div>
    </div>

    <div v-for="(iconbtn, i) in iconbtns" :key="i" class="page2__left__btnContainer" :class="iconbtn.class">
      <IconBtn
        :btn-text="iconbtn.btnText" :width="iconbtn.width" :height="iconbtn.height"
        :play-icon-color="iconbtn.playIconColor" external-class="page2__left__btn"
        :button-text-class="iconbtn.textclass" :subcontainer-class="iconbtn.subcontainerClass"
        @onClick="$router.push(`${iconbtn.path}`)"
      >
        <template #lefticon>
          <div :class="iconbtn.iconClass">
            <component :is="iconbtn.lefticon" />
          </div>
        </template>
      </IconBtn>
    </div>

    <div class="page2__left__signout d-flex justify-content-between align-items-center">
      <button class="page2__left__signout__btn btn p-0" @click="signout">
        <PlayIcon />
      </button>
      <p class="page2__left__signout__text">
        {{ signoutText }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
