<script>
import Right from './page3/Right.vue'
import Left from '@/components/pages/page3/Left.vue'
export default { components: { Left, Right } }
</script>

<template>
  <div class="page3__headerContainer">
    <div class="page3 page__bg">
      <div class="page3__container">
        <div class="row">
          <div class="col-xl-6 col-12">
            <!--  page2__mobileCol add in above col class if needed -->
            <Left />
          </div>
          <div class="col-6 d-xl-block d-none">
            <Right />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
