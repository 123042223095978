<script>
export default {
  props: ['color', 'externalClass'],
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" :class="externalClass">
    <g id="Polygon_5" data-name="Polygon 5" transform="translate(0 19) rotate(-90)" fill="none">
      <path d="M9.5,0,19,17H0Z" stroke="none" />
      <path
        d="M 9.5 6.149786949157715 L 5.113116264343262 14 L 13.88688373565674 14 L 9.5 6.149786949157715 M 9.5 0 L 19 17 L 0 17 L 9.5 0 Z"
        stroke="none" :fill="color || '#ed2894'"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
