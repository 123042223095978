<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30.166" height="16.583" viewBox="0 0 30.166 16.583">
    <g id="Group_1049" data-name="Group 1049" transform="translate(1.5 1.5)">
      <line
        id="Line_9" data-name="Line 9" x2="27.166" transform="translate(0 6.791)" fill="none" stroke="#f5f8fb"
        stroke-linecap="round" stroke-width="3"
      />
      <line
        id="Line_10" data-name="Line 10" x2="10.564" transform="translate(16.601 0)" fill="none"
        stroke="#f5f8fb" stroke-linecap="round" stroke-width="3"
      />
      <line
        id="Line_11" data-name="Line 11" x2="10.564" transform="translate(16.601 13.583)" fill="none"
        stroke="#f5f8fb" stroke-linecap="round" stroke-width="3"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
